@use "@wojo/ui/mixins" as *;

.inclusions {
    display: grid;
    gap: var(--g-spacing-lg);
    @include breakpoint-lg {
        gap: var(--g-spacing-xl);
        grid-template-columns: repeat(3, 1fr);
    }
}

.subheading {
    margin-bottom: var(--g-spacing-md);
    margin-top: var(--g-spacing-md);
}

.spinner {
    display: flex;
    justify-content: center;
}
